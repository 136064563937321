import React, { useEffect, useState } from "react";
import User from "../../helpers/login/userData";
import { Helmet } from "react-helmet";
import Config from "../../config";
/**
 *
 * @Components props.data
 * name: component name (not user for rendering)
 * notes : copyright info,
 * img: company logo,
 * items[]: footerlinks contains array of {url, name}
 */

const Chats = (props) => {
  console.log("chat props", props);
  const user = User();
  const Twilio = window["Twilio"];
  const MoveoAI = window["MoveoAI"];
  const instance = window["instance"];
  const twilioChatWorkerApi = Config.twilioChatWorkerApi;
  const [mode, setMode] = useState("dev");
  const [moveoFlag, setMoveoFlag] = useState(false);
  const [ukFlag, setUkFlag] = useState(false);
  let snippetIDprod = Config.AWSsnippetIDProd;
  let connectIDprod = Config.AWSconnectIDProd;
  let authenticateProd = Config.AWSAuthorizeURLProd;
  let snippetIDdev = Config.AWSsnippetIDDev;
  let connectIDdev = Config.AWSconnectIDDev;
  let authenticateDev = Config.AWSAuthorizeURLDev;
  let clientDev = Config.AWSClientDev;
  let clientProd = Config.AWSClientProd;
  //checking version of call center
  const callCenter = JSON.parse(
    window.localStorage.getItem("staticCallCenter")
  );
  const projectName = window.localStorage.getItem("staticProjectName")
    ? window.localStorage.getItem("staticProjectName").replaceAll('"', "")
    : "retail";
  const projectDesc = window.localStorage.getItem("staticProjectDesc")
    ? window.localStorage.getItem("staticProjectDesc").replaceAll('"', "")
    : "DesignLook";

  if (user?.userid != null) {
    window.analytics.identify(user.userid, {
      name: user.fullname,
      email: user?.email,
    });
  }

  /*Checking for domain anme if applies to UK to load correspondent AWS configuration
  Note: url must contain "-uk." in order to be recognize by domain name*/
  useEffect(() => {
    const checkUkDomain = () => {
      const url = window.location.hostname;
      if (url.includes("-uk.")) {
        setUkFlag(true);
      }
    };
    //DEV  and PROD use different instances of AWS so need different IDs

    checkUkDomain();
  }, [props]);

  /*Checking if Moveo object is loaded and call center matches the moveo to initialize chat */
  useEffect(() => {
    if (
      user?.email &&
      window.MoveoAI &&
      (callCenter === "mov" ||
        callCenter === "movprod" ||
        callCenter === "movten" ||
        callCenter === "movph")
    ) {
      console.log("MoveoAI CHAT INITIATED");
      if (!moveoFlag) {
        setMoveoFlag(true);
        moveoInit();
      }
    }
  }, [window.MoveoAI]);

  useEffect(() => {
    if (user?.email && callCenter === "twl") {
      const timeout = setTimeout(() => {
        webChatStart();
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [callCenter, user]);

  function isLoadedScript(functionName, callback) {
    window[functionName] = callback;
  }

  const moveoInit = () => {
    console.log("MoveoAI INITIATED moveoInit");
    let integrationId = Config.moveoIntegratedIdProd;
    let hostMoveo = Config.moveoHostProd;
    let image =
      "https://agentx-demo-staticweb-dev.hgsdigital.com/images/chat/moveo.png";

    if (user?.email && callCenter === "movprod") {
      integrationId = Config.moveoIntegratedIdProd;
      image =
        "https://agentx-demo-staticweb-dev.hgsdigital.com/images/chat/moveoprod.png";
    }
    if (user?.email && callCenter === "mov") {
      integrationId = Config.moveoIntegratedId;
      hostMoveo = Config.moveoHost;
      image =
        "https://agentx-demo-staticweb-dev.hgsdigital.com/images/chat/moveo.png";
    }

    if (user?.email && callCenter === "movten") {
      integrationId = Config.moveoIntegratedIdTen;
      image =
        "https://agentx-demo-staticweb-dev.hgsdigital.com/images/chat/moveotenant.png";
    }

    if (user?.email && callCenter === "movph") {
      integrationId = Config.moveoIntegratedIdPH;
      hostMoveo = Config.moveoHostPH;
      image =
        "https://agentx-demo-staticweb-dev.hgsdigital.com/images/chat/moveoprod.png";
    }

    if (
      callCenter === "mov" ||
      callCenter === "movprod" ||
      callCenter === "movten" ||
      callCenter === "movph"
    ) {
      console.log(
        "call center: " +
          callCenter +
          " ,projectName: " +
          (callCenter === "movph" ? projectName + "PH" : projectName) +
          " ,ID: " +
          integrationId +
          " ,User: ",
        user
      );
      MoveoAI?.init({
        integrationId: integrationId,
        element: document.getElementById("embed"),
        host: hostMoveo,
        bubble_image: image,
      })
        .then((instance) => {
          instance?.onSessionCreated(() => {
            instance.updateContext({
              user: { email: user?.email, display_name: user?.fullname },
              vertical: projectDesc,
              tags: [callCenter === "movph" ? projectName + "PH" : projectName],
            });
          });

          instance?.setCSSVariables({
            "--moveo-background-color": props?.site?.moveobackgroundcolor
              ? props?.site?.moveobackgroundcolor
              : "#000000",
            "--moveo-accent-color": props?.site?.moveoaccentcolor
              ? props?.site?.moveoaccentcolor
              : "#f4f4f4",
            // "--moveo-background-color-hover":
            //   props?.site?.moveobackgroundcolorhover,
            // "--moveo-review-number": props?.site?.moveoreviewnumber,
            // "--moveo-accent-color": props?.site?.moveoaccentcolor,
            // "--moveo-text-05": props?.site?.moveotext05,
            // "--moveo-text-04": props?.site?.moveotext04,
            // "--moveo-text-03": props?.site?.moveotext03,
            // "--moveo-text-02": props?.site?.moveotext02,
            // "--moveo-text-01": props?.site?.moveotext01,
            // "--moveo-ui-background-02": props?.site?.moveouibackground02,
            // "--moveo-ui-background-01": props?.site?.moveouibackground01,
          });
        })

        .catch((error) => console.error(error));
    }
  };

  /*WEBCHAT INIT*/
  const webChatStart = () => {
    //checking available agent and trigger the chat
    //let agent = false;
    if (twilioChatWorkerApi) {
      const fetchPromise = fetch(twilioChatWorkerApi);

      fetchPromise
        .then((response) => {
          return response.json();
        })
        .then((results) => {
          //if there are messages it will start Chat Session with TWILIO CHAT =>  meaning there will be available agents online
          if (results.message && !results.error) {
            const appConfig = {
              accountSid: results.AI,
              flexFlowSid: results.FL,
              context: {
                friendlyName: user?.fullname
                  ? user.fullname
                  : "Website Visitor",
                locationOrigin: window.location.origin,
                verticals: projectName,
              },
              componentProps: {
                MessagingCanvas: {
                  // predefinedMessage: false,
                  // showWelcomeMessage: true,
                  memberDisplayOptions: {
                    // yourDefaultName: "You",
                    // yourFriendlyNameOverride: false,
                    theirDefaultName: "Agent",
                    theirFriendlyNameOverride: false,
                  },
                },
              },
              startEngagementOnInit: false,
              preEngagementConfig: {
                description:
                  "Please provide some information about yourself to know how to address you in conversation",
                fields: [
                  {
                    label: "Enter your email to start conversation?",
                    type: "InputItem",
                    attributes: {
                      name: "Website_Visitor",
                      type: "email",
                      value: user?.email ? user?.email : "",
                      required: true,
                    },
                  },
                ],
                submitLabel: "Start conversation",
              },
            };
            Twilio.FlexWebChat.EntryPoint.defaultProps.tagline = "LIVE CHAT";
            Twilio.FlexWebChat.MainHeader.defaultProps.showImage = true;
            Twilio.FlexWebChat.MainHeader.defaultProps.titleText = "LIVE";
            Twilio.FlexWebChat.MainHeader.defaultProps.imageUrl = props.site
              ?.logo
              ? props.site.logo
              : "/images/" + projectName + "/logo.png";
            Twilio.FlexWebChat.MessagingCanvas.defaultProps.showWelcomeMessage = true;
            Twilio.FlexWebChat.MessageListItem.defaultProps.useFriendlyName = true;
            Twilio.FlexWebChat.MessageListItem.defaultProps.displayName = true;
            Twilio.FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage = false;
            Twilio.FlexWebChat.renderWebChat(appConfig);

            // }
          } else {
            console.log(
              "THERE ARE NO AVAILABLE AGENTS for communication, skipping chat..."
            );
          }
        })
        .catch((error) =>
          console.log("THERE IS AN ERROR in checking on available workers", {
            error,
          })
        );
    } else {
      alert("twilioChatWorkerApi", twilioChatWorkerApi);
    }
  };
  /*Switching production AWSC config to UK setup  */
  // if (ukFlag) {
  //   snippetIDprod = Config.AWSsnippetIDUk;
  //   connectIDprod = Config.AWSconnectIDUk;
  //   authenticateProd = Config.AWSAuthorizeURLUk;
  //   clientProd = Config.AWSClientUk;
  // }

  // CHATS will return available Chat according to selection of callCentar in  SWITCH module by injecting required <script>
  return (
    <>
      {/* {(callCenter === "mov" || callCenter === "movprod") && (
        <Helmet>
          <script
            id="moveoScript"
            crossOrigin=""
            rel="preload"
            src="https://cdn.jsdelivr.net/npm/@moveo-ai/web-client@latest/dist/web-client.min.js"
          ></script>
        </Helmet>
      )} */}
      {user?.email && callCenter === "amz"
        ? console.log(
            "AMAZON CONNECT UK CHECK:",
            window.location.origin
              .replace("http://", "")
              .replace("https://", "") ===
              "agentx-demo-staticweb-uk.hgsdigital.com"
          )
        : ""}
      {user?.email && callCenter === "amzph" && (
        <Helmet>
          <script type="text/javascript">
            {`(function(w, d, x, id){
    s=d.createElement('script');
    s.src='${Config.AWSClientPH}';
    s.async=1;
    s.id=id;
    d.getElementsByTagName('head')[0].appendChild(s);
    w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
  })(window, document, 'amazon_connect', '${Config.AWSconnectIDPH}');
  amazon_connect('styles', { iconType: 'CHAT', openChat: { color:'${props?.site?.chatcolor}' , backgroundColor: '${props?.site?.chatbackground}' }, closeChat: { color:'${props?.site?.chatcolor}' , backgroundColor: '${props?.site?.chatbackground}' } });
  amazon_connect('snippetId', '${Config.AWSsnippetIDPH}=');
  amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response' ]);
  amazon_connect('authenticate', function(callback) {
  window.fetch('${Config.AWSAuthorizeURLPH}?customer=${user?.email}').then(res => {
    res.json().then(data => {
      console.log(data.data);
      callback(data.data);
    });
  });
});
amazon_connect('customerDisplayName', function(callback) {
  const displayName = '${user?.fullname}';
  callback(displayName);
});
 
    `}
          </script>
        </Helmet>
      )}

      {user?.email && callCenter === "amz" && (
        <Helmet>
          <script type="text/javascript">
            {`(function(w, d, x, id){
    s=d.createElement('script');
    s.src='${
      window.location.origin.replace("http://", "").replace("https://", "") ===
      "agentx-demo-staticweb-uk.hgsdigital.com"
        ? Config.AWSClientUk
        : window.location.origin
            .replace("http://", "")
            .replace("https://", "") === "agentx-demo-staticweb.hgsdigital.com"
        ? clientProd
        : clientDev
    }';
    s.async=1;
    s.id=id;
    d.getElementsByTagName('head')[0].appendChild(s);
    w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
  })(window, document, 'amazon_connect', '${
    window.location.origin.replace("http://", "").replace("https://", "") ===
    "agentx-demo-staticweb-uk.hgsdigital.com"
      ? Config.AWSconnectIDUk
      : window.location.origin
          .replace("http://", "")
          .replace("https://", "") === "agentx-demo-staticweb.hgsdigital.com"
      ? connectIDprod
      : connectIDdev
  }');
  amazon_connect('styles', { iconType: 'CHAT', openChat: { color:'${
    props?.site?.chatcolor
  }' , backgroundColor: '${
              props?.site?.chatbackground
            }' }, closeChat: { color:'${
              props?.site?.chatcolor
            }' , backgroundColor: '${props?.site?.chatbackground}' } });
  amazon_connect('snippetId', '${
    window.location.origin.replace("http://", "").replace("https://", "") ===
    "agentx-demo-staticweb-uk.hgsdigital.com"
      ? Config.AWSsnippetIDUk
      : window.location.origin
          .replace("http://", "")
          .replace("https://", "") === "agentx-demo-staticweb.hgsdigital.com"
      ? snippetIDprod
      : snippetIDdev
  }=');
  amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response' ]);
  amazon_connect('authenticate', function(callback) {
  window.fetch('${
    window.location.origin.replace("http://", "").replace("https://", "") ===
    "agentx-demo-staticweb-uk.hgsdigital.com"
      ? Config.AWSAuthorizeURLUk
      : window.location.origin
          .replace("http://", "")
          .replace("https://", "") === "agentx-demo-staticweb.hgsdigital.com"
      ? authenticateProd
      : authenticateDev
  }?customer=${user?.email}').then(res => {
    res.json().then(data => {
      console.log(data.data);
      callback(data.data);
    });
  });
});


amazon_connect('customerDisplayName', function(callback) {
  const displayName = '${user?.fullname}';
  callback(displayName);
});
 
    `}
          </script>
        </Helmet>
      )}
      {user?.email && callCenter === "twl" && (
        <script
          src="https://assets.flex.twilio.com/releases/flex-webchat-ui/2.9.1/twilio-flex-webchat.min.js"
          integrity="sha512-yBmOHVWuWT6HOjfgPYkFe70bboby/BTj9TGHXTlEatWnYkW5fFezXqW9ZgNtuRUqHWrzNXVsqu6cKm3Y04kHMA=="
          crossOrigin=""
        ></script>
      )}

      <div id="embed"></div>
    </>
  );
};
export default Chats;
